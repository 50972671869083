import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import Head from 'react-helmet'

import Layout from '../../../components/Layout'

import Location from '../../../Location/Location.js'

const styles = (theme) => {
  return createStyles({
    title: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '36px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '34px',
      },
      fontSize: '24px',
    },
    howToApply: {
      [theme.breakpoints.up('lg')]: {
        marginTop: '120px',
        fontSize: '36px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '120px',
        fontSize: '34px',
      },
      marginTop: '120px',
      fontSize: '24px',
    },
    list: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
        margin: '0 0 5px 0',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
        margin: '0 0 5px 0',
      },
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '1.4',
      margin: '0 0 5px 0',
    },
    text: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
      },
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '1.4',
    },
    header: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '800',
        margin: '35px 0',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '800',
        margin: '35px 0',
      },
      fontSize: '12px',
      fontWeight: '800',
      margin: '20px 0',
    },
    description: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: '800',
      },
      [theme.breakpoints.up('md')]: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: '800',
      },
      textTransform: 'uppercase',
      textDecoration: 'underline',
      fontSize: '10px',
      fontWeight: '800',
    },
    section: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontFamily: 'Montserrat',
        maxWidth: '67.75rem',
        margin: '0 auto',
        marginTop: '5.8125rem',
        marginBottom: '7.1875rem',
      },
      [theme.breakpoints.up('md')]: {
        fontFamily: 'Montserrat',
        maxWidth: '67.75rem',
        margin: '0 auto',
        marginTop: '5.8125rem',
        marginBottom: '7.1875rem',
      },
      fontFamily: 'Montserrat',
      maxWidth: '90%',
      margin: '0 auto',
      marginTop: '5.8125rem',
      marginBottom: '7.1875rem',
    },
  })
}

const SeniorManager = ({ classes }) => {
  return (
    <Layout>
      <Head>
        <title>Senior Product Marketing Manager</title>
      </Head>
      <section className={classes.section}>
        <Typography variant="h1" className={classes.title}>
          Senior Product Marketing Manager
        </Typography>
        <Location location='Los Angeles' />
        <p className={classes.description}>Description</p>
        <h3 className={classes.header}>About Us:</h3>
        <p className={classes.text}>
          At Avocado Finance, we are a little bit peculiar. We are passionate about creating a personal finance experience that customers love and find valuable. If you are customer-obsessed, like interacting with people, and like learning new things, this is the place for you.
        </p>
        <h3 className={classes.header}>About You:</h3>
        <p className={classes.text}>
          As a Senior Product Marketing Manager, you will spearhead communication and programs to increase retention and engagement across email, SMS, push notifications, and in-app messaging. You will partner with stakeholders across the business to deliver cross-channel lifecycle marketing for the full suite of products/features that Avocado Finance has to offer. You’ll have a proven track record of implementing marketing technology tools, building automated customer journeys through machine learning models, and experience using the cross-channel marketing platform to execute on those campaigns.
        </p>
        <h3 className={classes.header}>What You Do:</h3>
        <div>
          <p className={classes.list}>-  Own create and execute strategies to activate, onboard, engage, educate, up-sell, and retain Avocado members
            throughout their lifecycle</p>
          <p className={classes.list}>-  Create detailed customer journey flows with actionable trigger points to drive conversion and engagement based on
            customer insights
          </p>
          <p className={classes.list}>-  Determine the strategy and framework for the rapid testing in CRM channels</p>
          <p className={classes.list}>-  Lead the product marketing team through 1:1 coaching and personal development opportunities</p>
          <p className={classes.list}>-  Manage relationships with 3rd party marketing technology partners</p>
          <p className={classes.list}>-  Partner with our Data Science team to leverage machine learning models to power lifecycle marketing communications </p>
          <p className={classes.list}>-  Lead the marketing direction of Design and Copy in marketing communications. </p>
          <p className={classes.list}>-  Partner with our Marketing Data Analyst to report on key learnings and revenue growth across the marketing initiatives
            you’re leading</p>
          <p className={classes.list}>-  Work closely with other Marketing leadership to help deliver on the product marketing roadmap and strategy</p>
          <p className={classes.list}>-  Drive cross-channel strategy across email, push, in-app message, and SMS</p>

          <p className={classes.text}>Candidates must be at least 18 years of age.</p>

          <p className={classes.text}>Avocado Finance does not sponsor for immigration, including for H-1B, TN, and other non-immigrant visas, for this role.</p>
        </div>
        <br />

        <h3 className={classes.description}>BASIC QUALIFICATIONS</h3>
        <div>
          <p className={classes.list}>-  6+ years product marketing experience ideally in an evolving fin-tech, eCommerce company or a strong B2C brand</p>
          <p className={classes.list}>-  3+ years of experience scaling and leading product marketing teams</p>
          <p className={classes.list}>-  Experience driving cross-channel strategy across email, push, in-app message, and SMS</p>
          <p className={classes.list}>-  In depth experience working with Braze, Iterable, or other cross-channel marketing platform </p>
          <p className={classes.list}>-  Extensive project management and presentation skills</p>
          <p className={classes.list}>-  Working knowledge of basic HTML fundamentals</p>
          <p className={classes.list}>-  Strong analytics skills, with a good grasp of MS Powerpoint, Word, Excel, Google Sheets, and Google docs.</p>
          <p className={classes.list}>-  To be comfortable managing risk within a fast-paced environment</p>
        </div>
        <br />

        <h3 className={classes.description}>PREFERRED QUALIFICATIONS</h3>
        <div>
          <p className={classes.list}>-  Proven work experience as a Senior Product Marketing Manager</p>
          <p className={classes.list}>-  Demonstrated positive work attitude</p>
          <p className={classes.list}>-  Comfortable working in a dynamic environment that requires adaptability</p>
          <p className={classes.list}>-  Interest in learning new skills</p>
          <p className={classes.list}>-  Prior experience at a Fintech startup</p>
        </div>
        <h3 className={classes.howToApply}>How to Apply</h3>
        <div>
          <p className={classes.text}>Send an email with your resume to <a href="mailto: support@avocadofinance.io" style={{ color: '#FF0080', fontWeight: '800', textDecoration: 'none' }}>support@avocadofinance.io</a>.</p>
        </div>
      </section>
    </Layout>
  )
}

export default withStyles(styles)(SeniorManager)